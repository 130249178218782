
.font-light {
    font-weight: 300;
}
.font-normal {
    font-weight: 400;
}
.font-medium-bold {
    font-weight: 500;
}
.font-bold {
    font-weight: 600;
}
.font-bolder {
    font-weight: 700;
}