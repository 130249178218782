/* Buttons */
.btn {
    line-height: 1;
    border-radius: 0.35rem;
    i {
      margin-right: 0.3125rem;
    }

    &.full-width {
        width: 100%;
        max-width: 440pt;
    }
  
    .btn-label {
      &:before {
        font-size: 1rem;
        line-height: 5px;
        vertical-align: middle;
      }
  
      &.btn-label-left {
        margin-right: 5px;
      }
  
      &.btn-label-right {
        margin-left: 5px;
      }
    }
  
    &.btn-rounded {
      @include border-radius(50px);
    }
  
    
    &.icon-btn {
      i {
        margin-right: 0;
      }
    }
  
    
  
    &.btn-sm {
      font-size: $btn-font-size-sm;
    }
  
    &.btn-lg {
      font-size: $btn-font-size-lg;
    }
  
    &.btn-xs {
      padding: $btn-padding-y-xs $btn-padding-x-xs;
      font-size: $btn-font-size-xs;
    }
  
    &.btn-primary,
    &.btn-danger,
    &.btn-info,
    &.btn-teal,
    &.btn-warning {
      color: $white;
    }
  
  
    &.btn-outline-primary:not(:disabled):not(.disabled) {
      &:active {
        color: $white !important;
      }
  
      &:hover {
        color: $white !important;
      }
    }
  
    &.btn-link {
      background: none;
      color: #ff941e;
  
      &:hover {
        color: darken(#ff941e, 10%);
      }
    }
  
    &.btn-light {
      color: $black;
      border-color: lighten($black, 85%);
    }
  
    &.btn-outline-light {
      @include button-outline-variant($light, color(white));
    }
  
    &.btn-outline-secondary {
      color: rgba($black, 0.5);
    }
  
    &.btn-inverse-secondary {
      background-color: rgba($secondary, 0.5);
      color: rgba($black, 0.5);
  
      &:hover {
        color: rgba($black, 0.5);
      }
    }
  
    &.btn-inverse-light {
      background-color: $white;
      color: rgba($black, 0.5);
      border-color: lighten($black, 85%);
  
      &:hover {
        color: rgba($black, 0.5);
        border-color: lighten($black, 85%);
      }
    }
  }
  
  .btn-group {
    border: $border-width solid $border-color;
    @include border-radius($btn-border-radius);
  
    .btn {
      border-top: none;
      border-bottom: none;
      border-left: none;
  
  
      &.btn-primary {
        border-color: darken($primary, 3%);
      }
  
      &.btn-secondary {
        border-color: darken($secondary, 3%);
      }
  
      &.btn-info {
        border-color: darken($info, 3%);
      }
  
      &.btn-warning {
        border-color: darken($warning, 3%);
      }
  
      &.btn-success {
        border-color: darken($success, 3%);
      }
  
      &.btn-danger {
        border-color: darken($danger, 3%);
      }
  
      &.btn-dark {
        border-color: darken($dark, 3%);
      }
  
      &.btn-light {
        border-color: darken($light, 3%);
      }
    }
  }
  

  

  
  
