@import "./variables";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

@import "./buttons";
@import "./cards";
@import "./inputs";
@import "./typography";

html,
body {
    font-family: 'Poppins', 'Helvetica', 'Sans Serif';
    font-size: 0.835rem;
    color: #2B3849;

    @media (min-width: 540px) {
        font-size: 0.95rem;
    }
}

.tabs-primary {
    ul.nav-pills {
        justify-content: center;

        li.nav-item {
            a.nav-link {
                color: $dark;
                border: 1px solid #C2C2C2;
                background-color: #fcfcfc;
                margin: 5px;
                padding: 5px 8px;
                min-width: 76px;
                text-align: center;
                border-radius: 0.35rem;
            }

            &.active {
                a.nav-link {
                    color: $white;
                    border: 1px solid #C2C2C2;
                    background: $primary;
                }
            }
        }
    }

    &.tabs-inline {
        .nav {
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content: flex-start;
            margin-bottom: 8px;
            padding: 10px 0;

            li.nav-item {
                flex: none;
                max-width: 170px;

                a.nav-link {
                    min-height: 85%;
                    vertical-align: middle;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px 8px 5px 0px;
                }
            }
        }

        .tab-content {
            margin-bottom: 10px;
        }
    }
}

.num-control {
    border: 0;

    .btn {
        font-size: 130%;
        font-weight: 500;
        border-width: 1px;
        border-style: solid;
        border-color: #F04B24;
        background-color: #fff;

        &.btn-muted {
            color: #F04B24;
        }
    }
}

.table {
    background: white;
    margin-bottom: 0;

    tbody {
        border: 0 !important;
        border-top: 2px solid #E0E0E0 !important;

        tr {
            border: 0 !important;
            border-bottom: 1px dashed #E0E0E0 !important;
        }

        tr:last-child {
            border-bottom: 1px dashed transparent !important;
        }

        td {
            vertical-align: middle;
        }
    }

    &.no-border {
        tbody {
            border: 0 !important;
            border-top: 0px solid transparent !important;

            tr {
                border-bottom: 0px solid transparent !important;
            }
        }
    }
}


.search-input {
    .svg-icon {
        max-height: 20px
    }

    input {
        background: #fff;
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        border-left: 0;
        padding-left: 0;
    }

    .input-group-prepend {
        .input-group-text {
            height: 100%;
            background: #fff;
            border-right: 0;
            border-radius: 0.75rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }


    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &::before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    input:checked+.slider {
        background-color: $primary;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px $primary;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}








.view-container {
    min-height: calc(100vh - $page-header-space);
    padding: 10px;
    font-size: 0.885rem;

    @media (min-width: 540px) {
        font-size: 1rem;
    }

    .bottom-container {
        position: fixed;
        left: 0;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

/* icon styles */

.svg-icon {
    height: 18px;
    line-height: 1.21;
    display: inline;
    vertical-align: middle;
    fill: currentColor
}

.page-main {
    position: relative;

    .page-main-header {
        background-color: $primary;
        min-height: $page-header-space;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
    }

    .page-body {
        margin-top: $page-header-space;
    }
}

#context-link-dropdown {
    inset: 100% 0px auto auto !important;

    li {
        padding: 8px 0;
    }

    .dropdown-item {
        .svg-icon {
            height: 18px;
        }
    }
}

.custom-select-crs {
    display: inline-block !important;

    .ng-select-container {
        border-radius: 6px;
        height: 27px !important;
        min-height: 27px !important;
        min-width: 80px;
    }

    .ng-value-label {
        font-size: 10px;
        font-weight: 500;
    }

    .ng-arrow-wrapper {
        padding-top: 3px;
    }

    .ng-dropdown-panel {
        min-width: 150px;
        right: 0;
        left: auto;
    }
}

/*bs-datepicker-container {
    transform: translate3d(25px, 96px, 0px) !important;
}*/

.bs-datepicker-container {
    padding: 0px !important;
}

/* VIDEO */
.full-width {
    video {
        width: 100%;
    }
}



/*Animations*/
@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}