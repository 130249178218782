
$primary: #F04B24;
$secondary: #e5e5e5;
$warning:#ffba57;
$default:#d6d6d6;
$danger: #ff5252;
$success:#7ca84a;
$info :#00bcd4;
$inverse: #37474f;
$dark : #424964;
$light : #e0e0e0;

$body-bg : #F6F7F8;
$body-color: #2B3849;

$page-header-space : 50px;

$btn-padding-y: 0.56rem;
$btn-padding-x: 1.375rem;
$btn-line-height: 1;
$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;
$btn-padding-y-sm: 0.50rem;
$btn-padding-x-sm: 0.81rem;
$btn-padding-y-lg: 0.94rem;
$btn-padding-x-lg: 1.94rem;
$btn-font-size: .875rem;
$btn-font-size-xs: .625rem;
$btn-font-size-sm: .875rem;
$btn-font-size-lg: .875rem;
$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;
