.card {
    border: 0;
    border-radius: 0.4rem;
    box-shadow: 0px 3px 6px #e0e0e0;
    padding: 0.3rem;
    .card-body{
        padding: 0.4rem 0.35rem;
    }
    &.mx-wd {
        max-width: 350px;
        width: 100vw;
        margin: 0 auto;
    }
}