.input-text {
    border-radius: 0.35rem;
    border: 1px solid #cccccc;
    background: #fff;
    min-height: 34px;
    vertical-align: middle;
    &.input-lg {
        font-size: 120%;
        line-height: 1.6;
    }
}